import { Controller } from "@hotwired/stimulus"
import { tempusDominus, DATE_DEFAULT_OPTIONS } from "../libs/tempus-dominus"

// Connects to data-controller="datepicker"
export default class extends Controller {
  static values = {
    format: { type: String, default: "dd.MM.yyyy" },
    locale: { type: String, default: "pl" },
    withTime: { type: Boolean, default: false },
    minDate: { type: String, default: "" },
    maxDate: { type: String, default: "" },
    dateRange: { type: Boolean, default: false },
    multiDateSeparator: { type: String, default: "," },
    sideBySide: { type: Boolean, default: false }
  }

  connect() {
    new tempusDominus.TempusDominus(this.element, this.defaultDateOptions)
  }

  get defaultDateOptions() {
    if (this.withTimeValue) {
      DATE_DEFAULT_OPTIONS.display.components.hours = true
      DATE_DEFAULT_OPTIONS.display.components.minutes = true
      DATE_DEFAULT_OPTIONS.display.components.seconds = true
    }
    else {
      DATE_DEFAULT_OPTIONS.display.components.hours = false
      DATE_DEFAULT_OPTIONS.display.components.minutes = false
      DATE_DEFAULT_OPTIONS.display.components.seconds = false
    }

    if (this.sideBySideValue) {
      DATE_DEFAULT_OPTIONS.display.sideBySide = true
    }

    return Object.assign(
      {
        dateRange: this.dateRangeValue,
        multipleDatesSeparator: this.multiDateSeparator,
        promptTimeOnDateChange: true,
        restrictions: {
          minDate: this.minDateOption,
          maxDate: this.maxDateOption
        },
        localization: {
          locale: this.localeValue,
          format: this.formatValue,
          startOfTheWeek: 1
        }
      }, DATE_DEFAULT_OPTIONS
    )
  }

  get minDateOption() {
    return (this.minDateValue == "" ? undefined : this.minDateValue)
  }

  get maxDateOption() {
    return (this.maxDateValue == "" ? undefined : this.maxDateValue)
  }
}
