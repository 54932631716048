import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";

hljs.registerLanguage("xml", xml);
hljs.registerLanguage("json", json);

document.addEventListener("turbo:load", (event) => {
  document.querySelectorAll("pre code").forEach((el) => {
    hljs.highlightElement(el);
  });
});
