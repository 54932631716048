import * as tempusDominus from "@eonasdan/tempus-dominus"

const DATE_DEFAULT_OPTIONS = {
  display: {
    components: {
      decades: true,
      year: true,
      month: true,
      date: true,
      hours: false,
      minutes: false,
      seconds: false
    },
    buttons: {
      today: true,
      clear: true,
      close: true
    }
  }
}

export { tempusDominus, DATE_DEFAULT_OPTIONS }
