import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-element"
export default class extends Controller {
  static values = {
    target: String
  }

  remove(event) {
    event.preventDefault();
    this.targets.forEach(target => target.remove())
  }

  get targets() {
    return document.querySelectorAll(this.targetValue)
  }
}
